import React, { useEffect, useContext } from "react";
import Seo from "../components/SEO";
import { navigate } from "gatsby";
import { UserAuthContext } from "../context";
import Layout from "../components/Layouts";

const Logout = () => {
  const { isAuthenticated, removeUser } = useContext(UserAuthContext);
  useEffect(() => {
    if (!isAuthenticated) navigate("/login");

    removeUser();
    navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {!isAuthenticated && (
        <Layout>
          <Seo title="Logout" />
        </Layout>
      )}
    </>
  );
};
export default Logout;
